export const apiFormSalesForce =
  "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DHr000003YWME";

// campaigns salesforce forms
export const ATSGoogle = "701Hr000001tESuIAM";
export const ATSLinkedIn = "701Hr000001tESvIAM";
export const EbookTendencias = "701Hr000001tESzIAM";
export const MasterclassTendencias = "701Hr000001tET0IAM";
export const SoftwareATSGeneral = "701Hr000001tET4IAM";
export const WebinarWPI = "701Hr000001tET9IAM";
