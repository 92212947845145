import { fullBasePath } from "@constants";
import { encode, ParsedUrlQuery } from "querystring";

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const fillUrlUtms = (targetHref: string, queryParams: ParsedUrlQuery): string => {
  const query = new URLSearchParams(encode(queryParams));
  const targetUrl = isValidUrl(targetHref) ? new URL(targetHref) : new URL(`${fullBasePath}${targetHref}`);
  const params = Array.from(query.entries());
  const utmEntries = params.filter(([key]) => key.startsWith("utm_"));

  if (utmEntries.length) {
    for (const [key, value] of utmEntries) {
      if (typeof value === "string") {
        targetUrl.searchParams.set(key, value);
      }
    }
  }

  return targetUrl.toString();
};
