import { useRouter } from "next/router";
import { useEffect, useState } from "react";

//styles in global.scss

const useVisibilityHeaderSearch = (path: string) => {
  const [scrollY, setScrollY] = useState(0);
  const router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (router.pathname === path) {
      const headerSearchForm = document.querySelector(
        ".mg_logout_header_magneto-ui-middle-section_nicsk"
      ) as HTMLElement | null;
      if (headerSearchForm) {
        headerSearchForm.classList.add("hide-form-search");
        if (scrollY > 300) {
          headerSearchForm.classList.remove("hide-form-search");
        }
      }
    }
  });

  return null;
};

export default useVisibilityHeaderSearch;
