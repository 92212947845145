import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import authSubject, { AuthSubject } from "@classes/authSubject.class";
import { AuthConcreteObserver } from "@classes/authConcreteObserver.class";
import { AuthRepository } from "@repositories/index";
import { AuthContext, initAuthContext } from "@context/auth.context";
import AuthListener from "@components/auth/listener/wrapper";

const withAuthHoc = <T,>(WrappedComponent: React.FC<T>) => {
  const WithAuth: React.FC<T & JSX.IntrinsicAttributes> = (props: T & JSX.IntrinsicAttributes) => {
    const [session, setSession] = useState(initAuthContext);

    const callbackToken = useCallback((subject: AuthSubject) => {
      AuthRepository.checkSession(subject.token || "").then((authData) =>
        setSession((prev) => ({ ...prev, ...authData }))
      );
    }, []);

    const authObserver = useMemo(() => {
      return new AuthConcreteObserver(callbackToken);
    }, [callbackToken]);

    useEffect(() => {
      authSubject.attach(authObserver);
      return () => {
        authSubject.detach(authObserver);
      };
    }, [authObserver]);

    return (
      <Fragment>
        <AuthContext.Provider value={{ ...session }}>
          <WrappedComponent {...props} />
          <AuthListener />
        </AuthContext.Provider>
      </Fragment>
    );
  };

  WithAuth.displayName = "WithAuthentication";

  return WithAuth;
};

export default withAuthHoc;
