import { TFunction } from "next-i18next";
import { jobsHostBase } from "@constants";

type TProps = {
  t: TFunction;
};

export const useSearch = ({ t }: TProps) => {
  return (value: string) => {
    const basisUrlSearch = jobsHostBase + t<string>("routes:jobs");
    if (value) {
      const valueNoSpaces = value.trim();
      const valueString = valueNoSpaces.replaceAll(" ", "+");
      const targetUrl = basisUrlSearch + "?q=" + valueString;
      window.location.replace(targetUrl);
    }
  };
};
