import React, { useEffect, useState } from "react";
import { TCookieGenerated } from "@@types/wordPress";
import style from "./style.module.scss";
import MagnetoIcon from "public/images/favicon-magneto.png";
import { googlePlay, appStore, appGallery, utmDownloadApp } from "@constants";
import Image from "next/image";
import { TFunction, withTranslation } from "next-i18next";
import { useRouter } from "next/router";

type RegistryBtnProps = {
  onClick: (type: TCookieGenerated) => void;
  click?: () => void;
  t: TFunction;
};

const RegistryBtnCookie: React.FC<RegistryBtnProps> = ({ onClick, click, t }) => {
  const btnText = t<string>("genericTexts:buttonTexts.downloadApp");
  const [slugUtm, setSlugUtm] = useState("");

  const router = useRouter();
  const { asPath } = router;

  useEffect(() => {
    const arraySlug = asPath.split("/");
    const lastSlug = arraySlug[arraySlug.length - 1];
    setSlugUtm(lastSlug == "" ? "es" : lastSlug);
  }, [asPath]);

  const createCookie = () => {
    onClick("registryBtn");
    redirectUser();
    if (click) {
      click();
    }
  };

  const generateStoreUrl = (storeUrl: string, utm: string, storeName: string) => {
    window.open(`${storeUrl}${storeUrl.includes("?") ? "&" : "?"}${utm}${storeName}_${slugUtm}`, "_blank");
  };

  const redirectUser = () => {
    const ua = window.navigator.userAgent;

    if (/iPhone|iPad|iPod/.test(ua)) {
      generateStoreUrl(appStore, utmDownloadApp, "appStore");
    } else if (/Android/.test(ua)) {
      generateStoreUrl(googlePlay, utmDownloadApp, "playStore");
    } else if (/harmonyOS|Huawei/.test(ua)) {
      generateStoreUrl(appGallery, utmDownloadApp, "appGallery");
    } else {
      generateStoreUrl(googlePlay, utmDownloadApp, "playStore");
    }
  };

  return (
    <button onClick={createCookie} className={style.btnDownloadApp} aria-label={btnText} id="modal-download-app">
      <Image src={MagnetoIcon.src} alt={btnText} width={20} height={20} />
      <span>{btnText}</span>
    </button>
  );
};

export default withTranslation()(RegistryBtnCookie);
