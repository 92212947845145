import React from "react";
import style from "./style.module.scss";
import { TCookieGenerated } from "@@types/wordPress";

type CreateGeneralCookieProps = {
  onClick: (type: TCookieGenerated) => void;
};

const CreateGeneralCookie: React.FC<CreateGeneralCookieProps> = ({ onClick }) => {
  const createCookie = () => {
    onClick("acceptGeneralCookie");
  };

  return (
    <button onClick={createCookie} className={style.buttonAccept}>
      {"Aceptar y cerrar"}
    </button>
  );
};

export default CreateGeneralCookie;
