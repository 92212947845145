import Link from "next/link";
import style from "./style.module.scss";
import { urlCookiesInfo } from "@constants/companyLinks.constant";
import GenerateCookie from "@components/cookies/component";

const InfoCookies = () => {
  return (
    <div id="cookiesContainer" className={style.cookiesContainer}>
      <div>
        <p>
          <strong>AVISO DE PRIVACIDAD Y USO DE COOKIES</strong>
        </p>
        <p>
          Al navegar en cualquier sitio de Magneto Global S.A.S., autorizas el uso y almacenamiento de cookies
          configuradas en el sitio web, a través de las cuales podremos realizar la captación y tratamiento de tus datos
          personales. Para más información, consulta nuestra
          <Link href={urlCookiesInfo} target="_blank">
            Política de Cookies
          </Link>
        </p>
      </div>
      <div>
        <GenerateCookie type="acceptGeneralCookie" />
      </div>
    </div>
  );
};

export default InfoCookies;
