/* eslint-disable react/prop-types */
import { GTM_B2B_ID, GTM_ID, GTM_PYMES_ID } from "@constants";
import Head from "next/head";

type GtmImplementationProps = {
  type?: string;
};

const GtmImplementation: React.FC<GtmImplementationProps> = ({ type }) => {
  const validationScript = (id: string) => {
    const gtmPayloadScript =
      `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.id='gtm';j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;if(!document.getElementById('gtm')){f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','${id}');`.replace(
        /\n/gm,
        ""
      );
    return gtmPayloadScript;
  };

  const scriptId = validationScript(type == "pymes" ? GTM_PYMES_ID : type == "b2b" ? GTM_B2B_ID : GTM_ID);

  return (
    <>
      <Head>
        <script dangerouslySetInnerHTML={{ __html: scriptId }} />
      </Head>
    </>
  );
};

export default GtmImplementation;
