import { isClientSide } from "@constants";
import { loadSdk, loadStyle } from "@utils/candidate";
import { WidgetRepository } from "@repositories/index";
import { useState } from "react";

const useOpenWidgetCreateAccount = () => {
  const [signUpLoading, setSignupLoading] = useState(false);

  const openCreateAccount = async function () {
    if (isClientSide) {
      const divModal = window.document.querySelector(".magneto-signup-v2-modal");
      divModal?.setAttribute("data-modal-status", "open");
    }
    setSignupLoading(true);
    try {
      const response = await WidgetRepository.getSignUpScripts();
      await loadStyle("magneto.ui.signup", response.payloadStyle);
      await loadSdk("magneto.sdk.signup", response.payloadScript);
    } finally {
      setSignupLoading(false);
    }
  };
  return { signUpLoading, openCreateAccount };
};

export default useOpenWidgetCreateAccount;
