import React from "react";
import { LogoutTemplate } from "magneto365.ui";
import { TFunction, withTranslation } from "next-i18next";
import useMagnetoHeader from "@hooks/magnetoUi/useMagnetoHeader";

type TProps = {
  t: TFunction;
};

const MagnetoLogoutHeader: React.FC<TProps> = ({ t }) => {
  const { logoutPropsHeader } = useMagnetoHeader({ t });
  return <LogoutTemplate {...logoutPropsHeader} />;
};

export default withTranslation(["header", "routes"])(MagnetoLogoutHeader);
