import React, { Fragment, useEffect, useState } from "react";
import DownloadApp from "../downloadAppPopUp/wrap";
import Cookies from "../generalCookies/component";
import { DOWNLOAD_APP_COOKIE } from "@constants";
import { EDeviceTypes } from "@enums/filter.core.enum";

type Props = {
  device?: EDeviceTypes;
};

const DynamicPopUp = ({ device }: Props) => {
  const [activePopUp, setActivePopUp] = useState(true);
  const isMobile = device === "mobile";

  useEffect(() => {
    const checkCookie = () => {
      const appCookie = localStorage.getItem(DOWNLOAD_APP_COOKIE);
      if (appCookie) {
        setActivePopUp(false);
      }
    };
    checkCookie();
  }, []);

  const handleClick = () => {
    setActivePopUp(!activePopUp);
  };

  return (
    <Fragment>{isMobile ? activePopUp ? <DownloadApp click={handleClick} /> : <Cookies /> : <Cookies />}</Fragment>
  );
};

export default DynamicPopUp;
