import React from "react";
import { LoginTemplate } from "magneto365.ui";
import { TFunction, withTranslation } from "next-i18next";
import useMagnetoHeader from "@hooks/magnetoUi/useMagnetoHeader";

type TProps = {
  t: TFunction;
};

const MagnetoLoginHeader: React.FC<TProps> = ({ t }) => {
  const { loginPropsHeader } = useMagnetoHeader({ t });
  return <LoginTemplate {...loginPropsHeader} />;
};

export default withTranslation(["header", "routes"])(MagnetoLoginHeader);
