/* eslint-disable react/prop-types */
import style from "./style.module.scss";

type CloseBtnProps = {
  click?: () => void;
};

const CloseBtnCookie: React.FC<CloseBtnProps> = ({ click }) => {
  const createCookie = () => {
    if (click) {
      click();
    }
  };

  return (
    <button onClick={createCookie} className={style.buttonAccept}>
      <i className="las la-times"></i>
    </button>
  );
};

export default CloseBtnCookie;
