export const loadStyle = (id: string, href: string): Promise<unknown> => {
  const hasLoaded = document.getElementById(id);
  if (hasLoaded) return Promise.resolve();
  const appStyle = window.document.createElement("link");
  appStyle.id = id;
  appStyle.type = "text/css";
  appStyle.rel = "stylesheet";
  appStyle.media = "screen";
  appStyle.href = href;
  window.document.head.insertBefore(appStyle, window.document.head.firstChild);
  return new Promise((resolve) => (appStyle.onload = resolve));
};
