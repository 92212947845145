import { IObserver } from "@interfaces/observer.interface";
import { ISubject } from "@interfaces/subject.interface";
import { AuthSubject } from "@classes/authSubject.class";

export class AuthConcreteObserver implements IObserver {
  constructor(private readonly callback: (subject: AuthSubject) => void) {}
  update(subject: ISubject): void {
    if (!(subject instanceof AuthSubject)) return;
    this.callback(subject);
  }
}
