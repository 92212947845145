/* eslint-disable camelcase */
import { ISeoData, TWpGlobal } from "#types/wordPress";
import { TEndpoint } from "@@types/api.core";
import { FILTER_API } from "@constants";
import Api from "@core/api.core";
import { AxiosRequestConfig } from "axios";
import getConfig from "next/config";
import { mergeRight } from "ramda";

const { wpApiHost } = getConfig().publicRuntimeConfig;

type PageByParentTypes = {
  parent: number;
  per_page?: number;
};

export class PagesRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    pages: {
      allPages: "/wp/v2/pages",
      pageById: `/wp/v2/pages/{id}${FILTER_API}`,
      pageByParent: `/wp/v2/pages${FILTER_API}`,
      pageSeoInfo: `/rankmath/v1/getHead?url=${wpApiHost}/{slug}`
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: `${wpApiHost}/wp-json` }, baseOptions));
  }

  async getAllPages(): Promise<TWpGlobal[]> {
    return this.get<TWpGlobal[]>(this.endpoints.pages.allPages).then((response) => response.data);
  }

  async getPageById(id: number): Promise<TWpGlobal> {
    const endpoint = this.endpoints.pages.pageById.replace("{id}", id.toString());
    return this.get<TWpGlobal>(endpoint).then((response) => response.data);
  }

  async getPageByParent(parent: number, per_page?: number): Promise<TWpGlobal[]> {
    const params: PageByParentTypes = { parent };
    if (per_page !== undefined) {
      params.per_page = per_page;
    }
    return this.get<TWpGlobal[]>(this.endpoints.pages.pageByParent, {
      params
    }).then((response) => response.data);
  }

  async getPageBySlug(slug: string): Promise<TWpGlobal[]> {
    return this.get<TWpGlobal[]>(this.endpoints.pages.pageByParent, {
      params: { slug }
    }).then((response) => response.data);
  }

  async getSeoPage(slug: string): Promise<ISeoData> {
    const endpoint = this.endpoints.pages.pageSeoInfo.replace("{slug}", slug.toString());
    return this.get<ISeoData>(endpoint).then((response) => response.data);
  }
}

export default new PagesRepository();
