/* eslint-disable react/prop-types */
import { useRouter } from "next/router";

type ParameterOriginCheckerProps = {
  children: React.ReactNode;
  parameter: string;
};

const ParameterOriginChecker: React.FC<ParameterOriginCheckerProps> = ({ children, parameter }) => {
  const {
    query: { origin }
  } = useRouter();

  return origin == parameter ? null : <>{children}</>;
};

export default ParameterOriginChecker;
