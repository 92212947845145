export const loadSdk = (id: string, src: string): Promise<unknown> => {
  const hasLoaded = document.getElementById(id);
  if (hasLoaded) return Promise.resolve();
  const currentTime = new Date();
  const appScript = window.document.createElement("script");
  appScript.id = id;
  appScript.type = "text/javascript";
  appScript.src = `${src}?ts=${currentTime.getTime()}`;
  window.document.body.appendChild(appScript);
  return new Promise((resolve) => (appScript.onload = resolve));
};
