import { ISession } from "#types/auth";
import { createContext } from "react";

export const initAuthContext: ISession = {
  user: null,
  isAuthenticated: false
};

export const AuthContext = createContext<ISession>(initAuthContext);

AuthContext.displayName = "AuthContext";
