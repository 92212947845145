import { WidgetRepository } from "@repositories";
import { loadSdk, loadStyle } from "@utils/candidate";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { isClientSide } from "@constants";

const ContainerModal = () => {
  const router = useRouter();
  useEffect(() => {
    if (router.query?.action && router.query?.action === "sign-up") {
      if (isClientSide) {
        const divModal = window.document.querySelector(".magneto-signup-v2-modal");
        divModal?.setAttribute("data-modal-status", "open");
      }
      WidgetRepository.getSignUpScripts().then((response) =>
        loadStyle("magneto.ui.signup", response.payloadStyle).then(() =>
          loadSdk("magneto.sdk.signup", response.payloadScript).then(() => {
            console.log("signUp");
          })
        )
      );
    }
  }, [router]);

  return <div className="magneto-signup-v2-modal"></div>;
};

export default ContainerModal;
