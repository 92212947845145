import { fullBasePath } from "@constants";
import { TFunction } from "next-i18next";

export type LoginForwardProps = {
  jobSlug?: string | null;
  action?: string | null;
  route: string;
  t: TFunction;
};

export const getForwardUrl = ({ jobSlug, action, route, t }: LoginForwardProps): string => {
  if (route.includes("404") || route.includes("500")) {
    const urlHome = new URL(t<string>("routes:jobs"), fullBasePath);
    return btoa(urlHome.toString());
  }
  const location = new URL(window.location.href);
  if (location.searchParams.has("forwardUrl")) {
    location.searchParams.delete("forwardUrl");
  }
  if (location.searchParams.has("widgetType")) {
    location.searchParams.delete("widgetType");
  }
  if (jobSlug && !location.pathname.includes(jobSlug)) {
    location.pathname = `${location.pathname}/${jobSlug}`;
  }
  action && location.searchParams.set("action", action);
  return btoa(location.toString());
};
