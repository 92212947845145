import React, { useEffect, useState } from "react";
import InfoCookies from "../infoCookies/infoCookies.component";
import { GENERAL_COOKIE } from "@constants";

const ValidateCookie = () => {
  const [acceptedCookie, setAcceptedCookie] = useState(false);

  useEffect(() => {
    const storedLs = localStorage.getItem(GENERAL_COOKIE);
    if (storedLs) {
      const acceptedCookieValue = JSON.parse(storedLs);
      setAcceptedCookie(acceptedCookieValue);
    }
  }, []);

  return !acceptedCookie ? <InfoCookies /> : null;
};

export default ValidateCookie;
