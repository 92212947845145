import { AxiosRequestConfig } from "axios";
import { mergeRight } from "ramda";
import { TEndpoint } from "@@types/api.core";
import { THeadersResponse } from "@@types/headers";
import { fullBasePath } from "@constants";
import Api from "@core/api.core";

export class ApiRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    api: {
      headers: "/headers"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: `${fullBasePath}/api` }, baseOptions));
  }

  async getHeaders(): Promise<THeadersResponse> {
    return this.get<THeadersResponse>(this.endpoints.api.headers).then((response) => response.data);
  }
}

export default new ApiRepository();
