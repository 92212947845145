import ArchiveTick from "public/assets/icons/ArchiveTick.svg";
// import BrifecaseTick from "public/assets/icons/BrifecaseTick.svg";
import AllJobs from "public/assets/icons/all-jobs.svg";
import DocumentText from "public/assets/icons/DocumentText.svg";
import MessageQuestion from "public/assets/icons/MessageQuestion.svg";
import SuggestedJobs from "public/assets/icons/suggested-jobs.svg";
import TaskSquare from "public/assets/icons/TaskSquare.svg";

const LeftTabButton = {
  type: "link" as const,
  text: "tabs.lookingForAJob"
};

const RightTabButton = {
  type: "link" as const,
  href: `/soluciones`,
  text: "tabs.recruiter"
};

const SearchbarText = {
  placeholder: "placeholder.searchbar"
};

export const MAGNETO_HEADER_DRAWER_MENU_SCHEMA = {
  headerDrawerProps: {
    leftTabLink: LeftTabButton,
    rightTabLink: RightTabButton
  },
  listMenuProps: {
    menuList: [
      { isInternal: true, text: "drawerMenu.home", url: "", slug: "inicio" },
      {
        isInternal: false,
        text: "drawerMenu.allJobs",
        url: "jobs",
        slug: "jobs"
      },
      {
        isInternal: false,
        text: "drawerMenu.jobsCity",
        url: "byCity",
        slug: "byCity"
      },
      {
        isInternal: false,
        text: "drawerMenu.jobsCompany",
        url: "byCompany",
        slug: "byCompany"
      },
      {
        isInternal: false,
        text: "drawerMenu.jobsIndustry",
        url: "bySector",
        slug: "bySector"
      },
      {
        isInternal: false,
        text: "drawerMenu.jobsProfessionalPosition",
        url: "byOccupation",
        slug: "byOccupation"
      },
      {
        isInternal: false,
        text: "drawerMenu.jobsProfessionalPositionByCity",
        url: "ocupacol",
        slug: "ocupacol"
      },
      {
        isInternal: false,
        text: "drawerMenu.jobsMostWanted",
        url: "searchHit",
        slug: "searchHit"
      },
      {
        isInternal: true,
        text: "drawerMenu.frequentQuestions",
        url: "/preguntas-frecuentes",
        slug: "preguntas-frecuentes/"
      },
      {
        isInternal: true,
        text: "drawerMenu.articles",
        url: "/blog",
        slug: "/blog"
      }
    ]
  },
  createAccountButton: {
    buttonText: "actionsButtons.createResume"
  },
  loginButton: {
    buttonText: "actionsButtons.login"
  }
};

export const MAGNETO_LOGOUT_HEADER_SCHEMA = {
  leftTabButton: LeftTabButton,
  rightTabButton: RightTabButton,
  searchbar: SearchbarText,
  signInLink: {
    type: "button" as const,
    text: "actionsButtons.login"
  },
  signUpButton: {
    buttonType: "button" as const,
    buttonText: "actionsButtons.createResume"
  }
};

export const MAGNETO_LOGIN_HEADER_SCHEMA = {
  logout: "userMenu.logOut",
  drawerMenu: MAGNETO_HEADER_DRAWER_MENU_SCHEMA.listMenuProps,
  menuUser: [
    {
      title: "userMenu.jobs",
      items: [
        {
          icon: AllJobs,
          text: "header:userMenu.allJobs",
          slug: "routes:jobs",
          url: "/jobs"
        },
        {
          icon: SuggestedJobs,
          text: "userMenu.suggested",
          slug: "/profile/jobs/suggested",
          url: "/profile/jobs/suggested"
        },
        {
          icon: ArchiveTick,
          text: "userMenu.saved",
          slug: "/profile/jobs/saved",
          url: "/profile/jobs/saved"
        },
        {
          icon: TaskSquare,
          text: "userMenu.processes",
          slug: ["/profile/postulations/active", "/profile/postulations/inactive"],
          url: "/profile/postulations/active"
        }
      ]
    },
    {
      title: "userMenu.myAccount",
      items: [
        {
          icon: DocumentText,
          text: "userMenu.resume",
          slug: "/profile/resume",
          url: "/profile/resume"
        },
        {
          icon: MessageQuestion,
          text: "userMenu.help",
          slug: "",
          url: "https://magnetoglobal.zendesk.com/hc/es-419/requests/new"
        }
      ]
    }
  ],
  menuUser1440: [
    {
      items: [
        {
          icon: MessageQuestion,
          text: "userMenu.help",
          slug: "",
          url: "https://magnetoglobal.zendesk.com/hc/es-419/requests/new"
        }
      ]
    }
  ],
  headerTabListJobs: [
    {
      tabType: "tabTitle" as const,
      url: "#",
      tabText: "userMenu.jobs"
    },
    {
      tabType: "tabOption" as const,
      url: "/empleos",
      tabText: "header:userMenu.allJobs",
      slug: "/empleos"
    },
    {
      tabType: "tabOption" as const,
      url: "/profile/jobs/suggested",
      tabText: "userMenu.suggested",
      slug: "/profile/jobs/suggested"
    },
    {
      tabType: "tabOption" as const,
      url: "/profile/jobs/saved",
      tabText: "userMenu.saved",
      slug: "/profile/jobs/saved"
    }
  ],
  headerTabListProcess: [
    {
      tabType: "tabTitle" as const,
      url: "#",
      tabText: "userMenu.processes"
    },
    {
      tabType: "tabOption" as const,
      url: "/profile/postulations/active",
      tabText: "userMenu.active",
      slug: "/profile/postulations/active"
    },
    {
      tabType: "tabOption" as const,
      url: "/profile/postulations/inactive",
      tabText: "userMenu.inactive",
      slug: "/profile/postulations/inactive"
    }
  ],
  headerTabListCV: [
    {
      tabType: "tabOption" as const,
      url: "/profile/resume",
      tabText: "userMenu.resume",
      slug: "/profile/resume"
    }
  ]
};
