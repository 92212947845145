import { TCookieGenerated } from "@@types/wordPress";
import CloseBtnCookie from "./downloadAppPopUp/closeBtnCookie/component";
import RegistryBtnCookie from "./downloadAppPopUp/registryBtnCookie/component";
import React from "react";
import CreateGeneralCookie from "./generalCookies/createCookie/createCookie.component";
import { DOWNLOAD_APP_COOKIE, GENERAL_COOKIE } from "@constants";

type GenerateCookieProps = {
  type: TCookieGenerated;
  click?: () => void;
};

const GenerateCookie: React.FC<GenerateCookieProps> = ({ type, click }) => {
  const handleCreateCookie = (type: TCookieGenerated) => {
    localStorage.setItem(
      type === "closePopUpApp" || type === "registryBtn" ? DOWNLOAD_APP_COOKIE : GENERAL_COOKIE,
      JSON.stringify(true)
    );
    const content = document.getElementById(
      type === "closePopUpApp" || type === "registryBtn" ? "contentDownloadApp" : "cookiesContainer"
    );
    if (!content) return;
    content.classList.add("hidden");
  };

  switch (type) {
    case "closePopUpApp":
      return <CloseBtnCookie click={click} />;

    case "registryBtn":
      return <RegistryBtnCookie onClick={handleCreateCookie} click={click} />;

    default:
      return <CreateGeneralCookie onClick={handleCreateCookie} />;
  }
};

export default GenerateCookie;
