import { useCallback, useEffect, useState } from "react";
import { TFunction } from "next-i18next";
import { useRouter } from "next/router";

// import { JobsContext, LandingContext } from "@context/jobs.context";
import WidgetRepository from "@repositories/widget.repository";
import { loadSdk, loadStyle } from "@utils/candidate";
import { setUrlState } from "@utils/url";

type Props = {
  t: TFunction;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useSignupWidget = ({ t, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  // const jobContext = useContext(JobsContext);
  // const landingContext = useContext(LandingContext);
  const router = useRouter();

  const onClickHandle = useCallback(
    async (event: Event) => {
      event.preventDefault();
      onClose?.(false);

      setLoading(true);
      const profileAppRoot = window.document.getElementById("magneto-signup-v2-modal");
      profileAppRoot?.setAttribute("data-modal-status", "open");

      try {
        const response = await WidgetRepository.getSignUpScripts();
        await loadStyle("magneto.ui.signup", response.payloadStyle);
        await loadSdk("magneto.sdk.signup", response.payloadScript);
      } catch (error) {
        console.error("Error loading signup scripts:", error);
      } finally {
        setLoading(false);
      }

      setUrlState({
        route: router.route,
        t
      });
    },
    [onClose, router.route, t]
  );

  useEffect(() => {
    const signUpButton: NodeListOf<Element> = window.document.querySelectorAll('body a[href="magneto://signup"]');
    signUpButton.forEach((item) => {
      item.addEventListener("click", onClickHandle);
    });

    return () => {
      signUpButton.forEach((item) => {
        item.removeEventListener("click", onClickHandle);
      });
    };
  }, [onClickHandle]);

  return { loading, onClickHandle };
};

export default useSignupWidget;
