/* eslint-disable react/prop-types */
import style from "./resolution.module.scss";
import { TFunction, withTranslation } from "next-i18next";
import Link from "next/link";
import { SPEResolution0070 } from "@constants";

type Props = {
  t: TFunction;
};

const Resolution0070: React.FC<Props> = ({ t }) => {
  return (
    <div className={style.resolution0070}>
      <p>
        {t<string>("linked")}{" "}
        <Link href={SPEResolution0070} target="_blank">
          {t<string>("resolution0070")}
        </Link>{" "}
      </p>
    </div>
  );
};

export default withTranslation("footer")(Resolution0070);
