import GtmImplementationProps from "@components/common/seoTags/gtmImplementation";
import GoogleCaptcha from "@components/companyForm/GoogleCaptcha";
import Cookies from "@components/cookies/generalCookies/component";
import { faviconB2b } from "@constants";
import { AuthContext } from "@context";
import { EDeviceTypes } from "@enums/filter.core.enum";
import useMagnetoFooter from "@hooks/magnetoUi/useMagnetoFooter";
import { Footer, MagnetoUIProvider } from "magneto365.ui";
import { TFunction, withTranslation } from "next-i18next";
import React, { Fragment, useContext } from "react";
import Favicon from "../favicon/component";
import Resolution0070 from "../layoutB2C/resolution0070/component";
import styles from "./layoutComp.module.scss";
// import Navigation from "./navigation";

type Props = {
  children: React.ReactNode;
  t: TFunction;
  device?: EDeviceTypes;
};

const LayoutB2b = ({ children, t, device }: Props) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { footerProps } = useMagnetoFooter({ t, isAuthenticated });

  return (
    <Fragment>
      <Favicon favicon={faviconB2b} />
      <GtmImplementationProps type="b2b" />
      {/* <Navigation /> */}
      <main className={styles.mainCompany}>
        <GoogleCaptcha>{children}</GoogleCaptcha>
      </main>
      <Cookies />
      <MagnetoUIProvider device={device}>
        <Footer {...footerProps} />
        <Resolution0070 />
      </MagnetoUIProvider>
    </Fragment>
  );
};

export default withTranslation()(LayoutB2b);
