import { TWpGlobal } from "@@types/wordPress";
import { FILTER_API_MENU } from "@constants";
import { AxiosRequestConfig } from "axios";
import getConfig from "next/config";
import { mergeRight } from "ramda";
import { TEndpoint } from "../@types/api.core";
import Api from "../core/api.core";

const { wpApiHost, wpApiHostPass } = getConfig().publicRuntimeConfig;

export class MenuRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    menus: {
      allMenus: "/wp/v2/menus",
      menuById: `/wp/v2/menu-items?menus={id}${FILTER_API_MENU}`
    }
  };
  constructor(baseOptions: AxiosRequestConfig = {}) {
    const authHeader = {
      method: "GET",
      Authorization: "Basic " + btoa("epinilla" + ":" + wpApiHostPass)
    };
    super(mergeRight({ baseURL: `${wpApiHost}/wp-json`, headers: { ...authHeader } }, baseOptions));
  }
  async getAllMenus() {
    return this.get<TWpGlobal[]>(this.endpoints.menus.allMenus).then((response) => response.data);
  }
  async getMenuById(id: number): Promise<TWpGlobal> {
    const endpoint = this.endpoints.menus.menuById.replace("{id}", id.toString());
    return this.get<TWpGlobal>(endpoint).then((response) => response.data);
  }
}

export default new MenuRepository();
