import React, { Fragment } from "react";
import Head from "next/head";

type FaviconProps = {
  favicon: string;
};

const Favicon: React.FC<FaviconProps> = ({ favicon }) => {
  return (
    <Fragment>
      <Head>
        <link rel="icon" href={favicon} />
        <link rel="apple-touch-icon" href={favicon} />
        <meta name="msapplication-TileImage" content={favicon} />
      </Head>
    </Fragment>
  );
};

export default Favicon;
