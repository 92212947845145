import { TFunction } from "next-i18next";
import { loggedUserHost } from "@constants";
import { getForwardUrl } from "@utils/url";

type TGetUrlSignIn = {
  jobSlug?: string | null;
  action?: string | null;
  route: string;
  t: TFunction;
};

export const getUrlSignIn = ({ jobSlug, action, route, t }: TGetUrlSignIn): string => {
  const forwardUrl = getForwardUrl({ jobSlug, action, route, t });
  const fullUrl = new URL(t<string>("routes:signIn"), loggedUserHost);
  forwardUrl && fullUrl.searchParams.set("forwardUrl", forwardUrl);
  return fullUrl.toString();
};
