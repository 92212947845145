import getConfig from "next/config";
import { mergeRight } from "ramda";
import { AxiosRequestConfig } from "axios";

import Api from "@core/api.core";
import { TEndpoint } from "#types/api.core";
import { ISession, TUser } from "#types/auth";

const { oauth2Host, jobsApiHost } = getConfig().publicRuntimeConfig;

export class AuthRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    auth: {
      checkSession: "/v1/health/oauth-check",
      revokeChallenge: "/v2/oauth2/xtoken",
      token: "/v2/oauth2/secure",
      session: "/v2/oauth2/session"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: oauth2Host }, baseOptions));
  }

  checkSession(token: string): Promise<ISession> {
    const request = this.get<ISession>(this.endpoints.auth.checkSession, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return request.then((response) => response.data).catch(() => ({ isAuthenticated: false, user: null }));
  }

  async revokeChallenge(challenge: string): Promise<void> {
    const { revokeChallenge } = this.endpoints.auth;
    await this.delete<{ isDeleted: boolean }>(`${revokeChallenge}/${challenge}`);
  }

  async requestSessionByChallenge(challenge: string): Promise<ISession> {
    const payload: AxiosRequestConfig = {
      params: { challenge },
      baseURL: jobsApiHost,
      withCredentials: true
    };
    return this.get<{ user: TUser }>(this.endpoints.auth.token, payload).then(
      ({ data: { user } }) => ({ isAuthenticated: true, user }),
      () => ({ isAuthenticated: false, user: null })
    );
  }
  async refreshSession() {
    const payload: AxiosRequestConfig = {
      baseURL: jobsApiHost,
      withCredentials: true
    };

    return this.post<{ user: TUser }>(this.endpoints.auth.session, undefined, payload).then(
      ({ data: { user } }) => ({ isAuthenticated: true, user }),
      () => ({ isAuthenticated: false, user: null })
    );
  }
}

export default new AuthRepository();
