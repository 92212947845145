import getConfig from "next/config";

export const localsFiles = ["footer", "header", "routes", "filters", "genericTexts"];
export const isClientSide: boolean = typeof window !== "undefined";
export const isServerSide: boolean = typeof window === "undefined";
export const valueRevalidate = 600;
export const fullBasePath: string = getConfig().publicRuntimeConfig.fullBasePath;
export const loggedUserHost: string = getConfig().publicRuntimeConfig.loggedUserHost;
export const legacyUserHost: string = getConfig().publicRuntimeConfig.legacyUserHost;
export const signUpUserHost: string = getConfig().publicRuntimeConfig.signUpUserHost || loggedUserHost;
export const oauthFrontHost: string = getConfig().publicRuntimeConfig.oauthFrontHost || "http://localhost:8080";
export const jobsHostBase: string = getConfig().publicRuntimeConfig.jobsHost || "http://localhost:8060/co";
export const HomeHostBase: string = getConfig().publicRuntimeConfig.urlHome || "http://localhost:8061";
export const language: string = getConfig().publicRuntimeConfig.language;
export const appHost: string = getConfig().publicRuntimeConfig.wpApiHost;
export const appHostPass: string = getConfig().publicRuntimeConfig.wpApiHostPass;
export const UserBackToken: string = getConfig().publicRuntimeConfig.publicUserBackToken;
export const supportZendesk: string = getConfig().publicRuntimeConfig.supportZendesk;
export const apiRegister: string = getConfig().publicRuntimeConfig.apiRegister;
export const apiFormEmblue = "https://php.embluemail.com/Integraciones/form_events/emblue_events.php";
export const recaptchaSite: string = getConfig().publicRuntimeConfig.recaptchaSite || "";
export const recaptchaSecretKey: string = getConfig().publicRuntimeConfig.recaptchaSecretKey || "";
export const registryHost: string = getConfig().publicRuntimeConfig.registryHost || "";
let productionHost: boolean;
appHost == "https://www.magneto365.com/manager" ? (productionHost = true) : (productionHost = false);

export { productionHost };
