import Cookies from "@components/cookies/generalCookies/component";
import { faviconB2c } from "@constants";
import { withTranslation } from "next-i18next";
import React, { Fragment } from "react";
import Favicon from "../favicon/component";
import styles from "./layoutEmpty.module.scss";

type Props = {
  children: React.ReactNode;
};

const LayoutEmpty = ({ children }: Props) => {
  return (
    <Fragment>
      <Favicon favicon={faviconB2c} />
      <main className={styles.main}>{children}</main>
      <Cookies />
    </Fragment>
  );
};

export default withTranslation()(LayoutEmpty);
