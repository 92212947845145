import { useCallback, useMemo } from "react";
import { TFunction } from "next-i18next";
import { footerMagnetoUI } from "@schemas/footer/footerMagnetoUI.schema";
import { fillUrlUtms } from "@utils/url";
import { SPEResolution, siteMap } from "@constants/companyLinks.constant";
import { useRouter } from "next/router";
import useSignupWidget from "@hooks/magnetoUi/useSignupWidget";

type TProps = {
  t: TFunction;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticated: boolean;
};

const useMagnetoFooter = ({ t, onClose, isAuthenticated }: TProps) => {
  const { query } = useRouter();

  const { personsMenu, magnetoMenu, magnetoLiteMenu, otherSolutionsMenu, legalMenu } = footerMagnetoUI;

  const { loading, onClickHandle } = useSignupWidget({ t, onClose });

  const urlCompanyPages = useCallback(
    ({ routePath, baseUrl }: { routePath: string; baseUrl: string }) => {
      const path = t<string>(routePath);
      return fillUrlUtms(`${baseUrl}${path}`, query);
    },
    [t, query]
  );

  const footerProps = useMemo(() => {
    const rightsReservedProps = {
      magnetoRightsText: t<string>("footer:rightsReserved"),
      mapSiteHref: fillUrlUtms(siteMap, query),
      mapSiteText: t<string>("footer:siteMap")
    };

    const magnetoResolutionProps = {
      resolutionText: t<string>("footer:linked"),
      resolutionHref: fillUrlUtms(SPEResolution, query),
      resolutionHrefText: t<string>("footer:resolution")
    };

    const followText = t<string>("footer:titles.socialMedia");

    const menuFooterLink = {
      personsMenu: {
        title: t<string>(personsMenu.title),
        heightContent: isAuthenticated ? 280 : personsMenu.heightContent,
        createAccount: onClickHandle,
        isLoading: loading,
        links: personsMenu.links.map(({ tag, ...rest }) => ({
          ...rest,
          tag: (tag.includes("login") || tag.includes("registerResume")) && isAuthenticated ? null : t(tag),
          href: urlCompanyPages(rest)
        }))
      },
      magnetoMenu: {
        title: t<string>(magnetoMenu.title),
        heightContent: magnetoMenu.heightContent,
        links: magnetoMenu.links.map(({ tag, ...rest }) => ({
          ...rest,
          tag: t<string>(tag),
          href: urlCompanyPages(rest)
        }))
      },
      magnetoLiteMenu: {
        title: t<string>(magnetoLiteMenu.title),
        heightContent: magnetoLiteMenu.heightContent,
        links: magnetoLiteMenu.links.map(({ tag, ...rest }) => ({
          ...rest,
          tag: t<string>(tag),
          href: urlCompanyPages(rest)
        }))
      },
      otherSolutionsMenu: {
        title: t<string>(otherSolutionsMenu.title),
        heightContent: otherSolutionsMenu.heightContent,
        links: otherSolutionsMenu.links.map(({ tag, ...rest }) => ({
          ...rest,
          tag: t<string>(tag),
          href: urlCompanyPages(rest)
        }))
      },
      legalMenu: {
        title: t<string>(legalMenu.title),
        heightContent: legalMenu.heightContent,
        links: legalMenu.links.map(({ tag, ...rest }) => ({
          ...rest,
          tag: t<string>(tag),
          href: urlCompanyPages(rest)
        }))
      }
    };

    return { rightsReservedProps, magnetoResolutionProps, followText, menuFooterLink };
  }, [
    query,
    t,
    personsMenu,
    magnetoMenu,
    magnetoLiteMenu,
    otherSolutionsMenu,
    legalMenu,
    isAuthenticated,
    loading,
    onClickHandle,
    urlCompanyPages
  ]);

  return { footerProps };
};

export default useMagnetoFooter;
